import { Layout } from "../../layouts/Layout/Layout";
import clsx from "clsx";
import s from "./CompetencePage.module.css";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useEffect, useState, useMemo } from "react";
import { Container } from "../../layouts/Container";
import { Title } from "../../ui/Title/Title";
import highlevel from "../../assets/icons/highlevel.svg";
import normallevel from "../../assets/icons/normallevel.svg";
import lowlevel from "../../assets/icons/lowlevel.svg";
import { Button } from "../../ui/Button/Button";
import { Card } from "../../components/Card/Card";
import { formatToRussianDate } from "../../helpers/formatToRussianDate";
import { getLevelDescription } from "../../helpers/getLevelDescription";
import { getLevelCategory } from "../../helpers/getLevelCategory";
import { getBarStyle } from "../../helpers/getBarStyle";
import { Spinner } from "../../ui/Spinner/Spinner";

export const CompetencePage = () => {
  const navigate = useNavigate();
  const { sphereId } = useParams();
  const [sphereResults, setSphereResults] = useState(null);
  const [finishedSphere, setFinishedSphere] = useState(false);
  const [assignedCourses, setAssignedCourses] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchSphereData = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/assessment-tests/result/sphere/${sphereId}`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );
        const data = await response.json();
        if (data && data.length > 0) {
          setSphereResults(data);
        }
      } catch (error) {
        console.error(error);
      }
    };

    fetchSphereData();
  }, [sphereId]);

  useEffect(() => {
    const fetchFinishedCourses = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/assessment-tests/results`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );
        const data = await response.json();

        const sphereResult = data.filter(
          (result) => result.sphere.id == sphereId
        );

        setFinishedSphere(sphereResult.length > 0);

        setIsLoading(false);
      } catch (error) {
        console.error(error);
      }
    };

    fetchFinishedCourses();
  }, [sphereId]);

  useEffect(() => {
    const fetchAssignedCourses = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/lectures`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );
        const data = await response.json();

        if (data && data.length > 0) {
          const relevantCourses = data.filter((course) =>
            course.lecture.spheres.some(
              (sphere) => sphere.id === parseInt(sphereId)
            )
          );

          const incompleteCourses = relevantCourses.filter(
            (course) => course.completed_lessons < course.lecture.lessons_count
          );

          setAssignedCourses(incompleteCourses);
        }
      } catch (error) {
        console.error(error);
      }
    };

    fetchAssignedCourses();
  }, [sphereResults, sphereId, finishedSphere]);

  const formattedResults = useMemo(() => {
    return sphereResults?.map((result) => ({
      ...result,
      formattedDate: formatToRussianDate(result.updated_at),
      levelCategory: getLevelCategory(result.level),
    }));
  }, [sphereResults]);

  const latestResult = formattedResults?.[formattedResults.length - 1];

  return (
    <Layout>
      <Container>
        <div className={s.wrapper}>
          {isLoading ? (
            <Spinner className={s.spinner} />
          ) : latestResult ? (
            <>
              <Button text="← Назад" type="back" onClick={() => navigate(-1)} />

              <Title>{latestResult?.sphere?.name}</Title>

              <div className={s.level__rang}>
                <div className={s.level__wrapper}>
                  <img
                    className={s.level__icon}
                    src={latestResult?.levelCategory.icon}
                    alt="level"
                  />
                  <p
                    className={clsx(
                      s.level__text,
                      latestResult?.levelCategory.className
                    )}
                  >
                    {latestResult?.levelCategory.text}
                  </p>
                </div>

                <p
                  className={clsx(
                    s.level__desc,
                    latestResult?.levelCategory.className
                  )}
                >
                  {getLevelDescription(latestResult?.levelCategory.text)}
                </p>
              </div>

              <div className={s.level__rating__wrapper}>
                {formattedResults?.map((result, index, arr) => (
                  <div key={index} className={s.level__rating__card}>
                    <div
                      className={s.level__rating}
                      style={getBarStyle(
                        result.levelCategory.text,
                        index === arr.length - 1
                      )}
                    />
                    <p
                      className={clsx(
                        s.date,
                        index === arr.length - 1 && s.date__last
                      )}
                    >
                      {result.formattedDate}
                    </p>
                  </div>
                ))}
              </div>

              {/* НАЗНАЧЕННЫЕ КУРСЫ */}
              {finishedSphere &&
                latestResult?.levelCategory?.text === "Дефицитарный уровень" &&
                assignedCourses.length > 0 && (
                  <div className={s.courses}>
                    <h2 className={s.courses__title}>Назначенные курсы</h2>
                    <div className={s.courses__wrapper}>
                      {assignedCourses.map((course, index) => (
                        <Link
                          to={`/my_courses/page_course/${course.lecture.id}`}
                          key={index}
                        >
                          <Card
                            img={course.lecture.image}
                            alt="Картинка"
                            title={course.lecture.title}
                            chipText={course.lecture.tag || undefined}
                            chip="green"
                            descr={course.lecture.description}
                            buttonType="round"
                            buttonText=""
                            id={course.lecture.id}
                          />
                        </Link>
                      ))}
                    </div>
                  </div>
                )}
            </>
          ) : (
            <h2 className={s.courses__title}>Результаты не найдены!</h2>
          )}
        </div>
      </Container>
    </Layout>
  );
};

// import { Layout } from "../../layouts/Layout/Layout";
// import clsx from "clsx";
// import s from "./CompetencePage.module.css";
// import { useNavigate, useParams } from "react-router-dom";
// import { useEffect, useState, useMemo } from "react";
// import { Container } from "../../layouts/Container";
// import { Title } from "../../ui/Title/Title";
// import highlevel from "../../assets/icons/highlevel.svg";
// import normallevel from "../../assets/icons/normallevel.svg";
// import lowlevel from "../../assets/icons/lowlevel.svg";
// import { Button } from "../../ui/Button/Button";
// import { Card } from "../../components/Card/Card";

// // ФУНКЦИЯ ДЛЯ ОПРЕДЕЛЕНИЯ УРОВНЯ РЕЗУЛЬТАТА
// const getLevelCategory = (level) => {
//   if (level < 69) {
//     return {
//       icon: lowlevel,
//       text: "Дефицитарный уровень",
//       className: s.level__text_low,
//     };
//   } else if (level <= 90) {
//     return {
//       icon: normallevel,
//       text: "Требуемый уровень",
//       className: s.level__text_normal,
//     };
//   } else {
//     return {
//       icon: highlevel,
//       text: "Уровень превосходства",
//       className: s.level__text_high,
//     };
//   }
// };

// // ФУНКЦИЯ ФОРМАТИРОВАНИЯ ДАТЫ, ВЫПИСАТЬ В HELPERS
// function formatToRussianDate(isoDateString) {
//   const date = new Date(isoDateString);
//   const options = {
//     day: "numeric",
//     month: "long",
//     year: "numeric",
//   };
//   return date.toLocaleDateString("ru-RU", options).replace(" г.", "");
// }

// // ФУНКЦИЯ ОПРЕДЕЛЕНИЯ ЦВЕТА СТОЛБИКА С РЕЙТИНГОМ
// const getBarStyle = (level, isLast) => {
//   let height, backgroundColor, opacity;
//   switch (level) {
//     case "Дефицитарный уровень":
//       height = "50px";
//       backgroundColor = isLast ? "#FF7D1E" : "rgba(9, 48, 106, 0.4)";
//       opacity = "1";
//       break;
//     case "Требуемый уровень":
//       height = "100px";
//       backgroundColor = isLast ? "#8ac540" : "rgba(9, 48, 106, 0.4)";
//       opacity = "1";
//       break;
//     case "Уровень превосходства":
//       height = "150px";
//       backgroundColor = isLast ? "#00a442" : "rgba(9, 48, 106, 0.4)";
//       opacity = "1";
//       break;
//     default:
//       height = "50px";
//       backgroundColor = "rgba(9, 48, 106, 0.4)";
//   }
//   return { height, backgroundColor };
// };

// // ФУНКЦИЯ ОПРЕДЕЛЕНИЯ ОПИСАНИЯ УРОВНЯ
// const getLevelDescription = (level) => {
//   switch (level) {
//     case "Дефицитарный уровень":
//       return "Степень проявления компетенции, отражающая дефициты \nв способностях для выполнения профессиональных задач \nв соответствии с критерием оценки";
//     case "Требуемый уровень":
//       return "Степень проявления компетенции, отражающая способности, достаточные для выполнения профессиональных задач в соответствии с критерием оценки, и учитывающая требования федеральныхи локальных нормативных правовых актов";
//     case "Уровень превосходства":
//       return "Степень проявления компетенции, превосходящая достаточные способности для выполнения профессиональных задач в соответствии с критерием/ями";
//     default:
//       return "Степень проявления компетенции, отражающая дефициты \nв способностях для выполнения профессиональных задач \nв соответствии с критерием оценки";
//   }
// };

// export const CompetencePage = () => {
//   // Для события перемещения на страницу назад, React-Router
//   const navigate = useNavigate();

//   // Получаю ID сферы из адресной строки, React-Router
//   const { sphereId } = useParams();

//   // Результаты всех ОЦЕНОЧНЫХ СЕССИЙ по данной СФЕРЕ КОМПЕТЕНЦИЙ
//   const [sphereResults, setSphereResults] = useState(null);

//   // Назначенные
//   const [assignedCourses, setAssignedCourses] = useState([]);

//   // ПОЛУЧЕНИЕ ДАННЫХ С СЕРВЕРА
//   useEffect(() => {
//     const fetchSphereData = async () => {
//       try {
//         const response = await fetch(
//           `${process.env.REACT_APP_API_URL}/assessment-tests/result/sphere/${sphereId}`,
//           {
//             headers: {
//               Authorization: `Bearer ${localStorage.getItem("token")}`,
//             },
//           }
//         );
//         const data = await response.json();
//         if (data && data.length > 0) {
//           setSphereResults(data);
//         }
//       } catch (error) {
//         console.error(error);
//       }
//     };

//     fetchSphereData();
//   }, [sphereId]);

//   useEffect(() => {
//     const fetchAssignedCourses = async () => {
//       try {
//         const response = await fetch(
//           `${process.env.REACT_APP_API_URL}/lectures`,
//           {
//             headers: {
//               Authorization: `Bearer ${localStorage.getItem("token")}`,
//             },
//           }
//         );
//         const data = await response.json();
//         if (data && data.length > 0) {
//           console.log(data);
//           console.log(latestResult);
//           data.forEach((course) => {
//             console.log(course?.lecture.title, latestResult?.sphere?.name);
//             if (course?.lecture.title === latestResult?.sphere?.name) {
//               setAssignedCourses(Array.from(course));
//             }
//           });
//         }
//       } catch (error) {
//         console.error(error);
//       }
//     };

//     fetchAssignedCourses();
//   }, [sphereId]);

//   // ВЫЧИСЛЯЕМ УРОВНИ ТОЛЬКО РАЗ ПОСЛЕ ПОЛУЧЕНИЯ ДАННЫХ
//   const formattedResults = useMemo(() => {
//     return sphereResults?.map((result) => ({
//       ...result,
//       formattedDate: formatToRussianDate(result.updated_at),
//       levelCategory: getLevelCategory(result.level),
//     }));
//   }, [sphereResults]);

//   // ПОЛУЧЕНИЕ ПОСЛЕДНЕГО РЕЗУЛЬТАТА ТЕСТА
//   const latestResult = formattedResults?.[formattedResults.length - 1];

//   console.log(assignedCourses);
//   return (
//     <Layout>
//       <Container>
//         <div className={s.wrapper}>
//           <Button text="← Назад" type="back" onClick={() => navigate(-1)} />

//           <Title>{latestResult?.sphere?.name}</Title>

//           <div className={s.level__rang}>
//             <div className={s.level__wrapper}>
//               <img
//                 className={s.level__icon}
//                 src={latestResult?.levelCategory.icon}
//                 alt="level"
//               />
//               <p
//                 className={clsx(
//                   s.level__text,
//                   latestResult?.levelCategory.className
//                 )}
//               >
//                 {latestResult?.levelCategory.text}
//               </p>
//             </div>

//             <p
//               className={clsx(
//                 s.level__desc,
//                 latestResult?.levelCategory.className
//               )}
//             >
//               {getLevelDescription(latestResult?.levelCategory.text)}
//             </p>
//           </div>

//           <div className={s.level__rating__wrapper}>
//             {formattedResults?.map((result, index, arr) => (
//               <div key={index} className={s.level__rating__card}>
//                 <div
//                   className={s.level__rating}
//                   style={getBarStyle(
//                     result.levelCategory.text,
//                     index === arr.length - 1
//                   )}
//                 />
//                 <p
//                   className={clsx(
//                     s.date,
//                     index === arr.length - 1 && s.date__last
//                   )}
//                 >
//                   {result.formattedDate}
//                 </p>
//               </div>
//             ))}
//           </div>

//           {latestResult?.levelCategory?.text === "Дефицитарный уровень" && (
//             <div className={s.courses}>
//               <h2 className={s.courses__title}>Назначенные курсы</h2>
//               <div className={s.courses__wrapper}>
//                 {assignedCourses.map((course, index) => (
//                   <Card
//                     key={index}
//                     img={course.image}
//                     alt="Картинка"
//                     title={course.title}
//                     chipText={course.tag || undefined}
//                     chip="green"
//                     descr={course.description}
//                     buttonType="round"
//                     buttonText="Text"
//                     id={course.id}
//                     // page="AllCourses"
//                     // id={course.lecture.id}
//                   />
//                 ))}
//               </div>
//             </div>
//           )}
//         </div>
//       </Container>
//     </Layout>
//   );
// };

// // ===============================
// import { Layout } from "../../layouts/Layout/Layout";
// import clsx from "clsx";
// import s from "./CompetencePage.module.css";
// import { useNavigate, useParams } from "react-router-dom";
// import { useEffect, useState, useMemo } from "react";
// import { Container } from "../../layouts/Container";
// import { Title } from "../../ui/Title/Title";
// import highlevel from "../../assets/icons/highlevel.svg";
// import normallevel from "../../assets/icons/normallevel.svg";
// import lowlevel from "../../assets/icons/lowlevel.svg";
// import { Button } from "../../ui/Button/Button";
// import { Card } from "../../components/Card/Card";

// // ФУНКЦИЯ ДЛЯ ОПРЕДЕЛЕНИЯ УРОВНЯ РЕЗУЛЬТАТА
// const getLevelCategory = (level) => {
//   if (level < 69) {
//     return {
//       icon: lowlevel,
//       text: "Дефицитарный уровень",
//       className: s.level__text_low,
//     };
//   } else if (level <= 90) {
//     return {
//       icon: normallevel,
//       text: "Требуемый уровень",
//       className: s.level__text_normal,
//     };
//   } else {
//     return {
//       icon: highlevel,
//       text: "Уровень превосходства",
//       className: s.level__text_high,
//     };
//   }
// };

// // ФУНКЦИЯ ФОРМАТИРОВАНИЯ ДАТЫ, ВЫПИСАТЬ В HELPERS
// function formatToRussianDate(isoDateString) {
//   const date = new Date(isoDateString);
//   const options = {
//     day: "numeric",
//     month: "long",
//     year: "numeric",
//   };
//   return date.toLocaleDateString("ru-RU", options).replace(" г.", "");
// }

// // ФУНКЦИЯ ОПРЕДЕЛЕНИЯ ЦВЕТА СТОЛБИКА С РЕЙТИНГОМ
// const getBarStyle = (level, isLast) => {
//   let height, backgroundColor, opacity;
//   switch (level) {
//     case "Дефицитарный уровень":
//       height = "50px";
//       backgroundColor = isLast ? "#FF7D1E" : "rgba(9, 48, 106, 0.4)";
//       opacity = "1";
//       break;
//     case "Требуемый уровень":
//       height = "100px";
//       backgroundColor = isLast ? "#8ac540" : "rgba(9, 48, 106, 0.4)";
//       opacity = "1";
//       break;
//     case "Уровень превосходства":
//       height = "150px";
//       backgroundColor = isLast ? "#00a442" : "rgba(9, 48, 106, 0.4)";
//       opacity = "1";
//       break;
//     default:
//       height = "50px";
//       backgroundColor = "rgba(9, 48, 106, 0.4)";
//   }
//   return { height, backgroundColor };
// };

// // ФУНКЦИЯ ОПРЕДЕЛЕНИЯ ОПИСАНИЯ УРОВНЯ
// const getLevelDescription = (level) => {
//   switch (level) {
//     case "Дефицитарный уровень":
//       return "Степень проявления компетенции, отражающая дефициты \nв способностях для выполнения профессиональных задач \nв соответствии с критерием оценки";
//     case "Требуемый уровень":
//       return "Степень проявления компетенции, отражающая способности, достаточные для выполнения профессиональных задач в соответствии с критерием оценки, и учитывающая требования федеральныхи локальных нормативных правовых актов";
//     case "Уровень превосходства":
//       return "Степень проявления компетенции, превосходящая достаточные способности для выполнения профессиональных задач в соответствии с критерием/ями";
//     default:
//       return "Степень проявления компетенции, отражающая дефициты \nв способностях для выполнения профессиональных задач \nв соответствии с критерием оценки";
//   }
// };

// export const CompetencePage = () => {
//   // Для события перемещения на страницу назад, React-Router
//   const navigate = useNavigate();

//   // Получаю ID сферы из адресной строки, React-Router
//   const { sphereId } = useParams();

//   // Результаты всех ОЦЕНОЧНЫХ СЕССИЙ по данной СФЕРЕ КОМПЕТЕНЦИЙ
//   const [sphereResults, setSphereResults] = useState(null);

//   const [finishedSphere, setFinishedSphere] = useState(false);

//   // Назначенные
//   const [assignedCourses, setAssignedCourses] = useState([]);

//   // ПОЛУЧЕНИЕ ДАННЫХ С СЕРВЕРА
//   useEffect(() => {
//     const fetchSphereData = async () => {
//       try {
//         const response = await fetch(
//           `${process.env.REACT_APP_API_URL}/assessment-tests/result/sphere/${sphereId}`,
//           {
//             headers: {
//               Authorization: `Bearer ${localStorage.getItem("token")}`,
//             },
//           }
//         );
//         const data = await response.json();
//         if (data && data.length > 0) {
//           setSphereResults(data);
//         }
//       } catch (error) {
//         console.error(error);
//       }
//     };

//     fetchSphereData();
//   }, [sphereId]);

//   // ЧТО БЫ УЗНАТЬ ЗАВЕРШЕНА ЛИ НАЗНАЧЕННАЯ СЕССИЯ ПО ДАННОЙ СФЕРЕ
//   useEffect(() => {
//     const fetchFinishedCourses = async () => {
//       try {
//         const response = await fetch(
//           `${process.env.REACT_APP_API_URL}/assessment-tests/results`,
//           {
//             headers: {
//               Authorization: `Bearer ${localStorage.getItem("token")}`,
//             },
//           }
//         );
//         const data = await response.json();

//         const sphereResult = data.filter(
//           (result) => result.sphere.id == sphereId
//         );

//         console.log(sphereResult);

//         // ЕСЛИ СЕССИЯ ЗАВЕРШЕНА, ФЛАГ ЗАВЕРШЕННОЙ СЕССИИ ПО ДАННОЙ СФЕРЕ СТАВИМ В TRUE
//         setFinishedSphere(sphereResult.length > 0);
//       } catch (error) {
//         console.error(error);
//       }
//     };

//     fetchFinishedCourses();
//   }, [sphereId]);

//   console.log(finishedSphere);

//   useEffect(() => {
//     const fetchAssignedCourses = async () => {
//       try {
//         const response = await fetch(
//           `${process.env.REACT_APP_API_URL}/lectures`,
//           {
//             headers: {
//               Authorization: `Bearer ${localStorage.getItem("token")}`,
//             },
//           }
//         );
//         const data = await response.json();

//         console.log(data);

//         if (data && data.length > 0 && !finishedSphere) {
//           // const latestResult = sphereResults?.[sphereResults.length - 1];
//           // const relevantCourses = data.filter(
//           //   (course) => course?.lecture.title === latestResult?.sphere?.name
//           // );
//           // const latestResult = sphereResults?.[sphereResults.length - 1];
//           console.log(latestResult);

//           const relevantCourses = data.filter((course) =>
//             course.lecture.spheres.some(
//               (sphere) => sphere.id === parseInt(sphereId)
//             )
//           );

//           console.log(relevantCourses);
//           setAssignedCourses(relevantCourses);
//         }
//       } catch (error) {
//         console.error(error);
//       }
//     };

//     fetchAssignedCourses();
//   }, [sphereResults]);

//   // ВЫЧИСЛЯЕМ УРОВНИ ТОЛЬКО РАЗ ПОСЛЕ ПОЛУЧЕНИЯ ДАННЫХ
//   const formattedResults = useMemo(() => {
//     return sphereResults?.map((result) => ({
//       ...result,
//       formattedDate: formatToRussianDate(result.updated_at),
//       levelCategory: getLevelCategory(result.level),
//     }));
//   }, [sphereResults]);

//   // ПОЛУЧЕНИЕ ПОСЛЕДНЕГО РЕЗУЛЬТАТА ТЕСТА
//   const latestResult = formattedResults?.[formattedResults.length - 1];

//   const completedCourses = assignedCourses.filter(
//     (course) => course.completed_lessons === course.lecture.lessons_count
//   );

//   return (
//     <Layout>
//       <Container>
//         <div className={s.wrapper}>
//           <Button text="← Назад" type="back" onClick={() => navigate(-1)} />

//           <Title>{latestResult?.sphere?.name}</Title>

//           <div className={s.level__rang}>
//             <div className={s.level__wrapper}>
//               <img
//                 className={s.level__icon}
//                 src={latestResult?.levelCategory.icon}
//                 alt="level"
//               />
//               <p
//                 className={clsx(
//                   s.level__text,
//                   latestResult?.levelCategory.className
//                 )}
//               >
//                 {latestResult?.levelCategory.text}
//               </p>
//             </div>

//             <p
//               className={clsx(
//                 s.level__desc,
//                 latestResult?.levelCategory.className
//               )}
//             >
//               {getLevelDescription(latestResult?.levelCategory.text)}
//             </p>
//           </div>

//           <div className={s.level__rating__wrapper}>
//             {formattedResults?.map((result, index, arr) => (
//               <div key={index} className={s.level__rating__card}>
//                 <div
//                   className={s.level__rating}
//                   style={getBarStyle(
//                     result.levelCategory.text,
//                     index === arr.length - 1
//                   )}
//                 />
//                 <p
//                   className={clsx(
//                     s.date,
//                     index === arr.length - 1 && s.date__last
//                   )}
//                 >
//                   {result.formattedDate}
//                 </p>
//               </div>
//             ))}
//           </div>

//           {latestResult?.levelCategory?.text === "Дефицитарный уровень" &&
//             finishedSphere &&
//             completedCourses.length == 0 && (
//               <div className={s.courses}>
//                 <h2 className={s.courses__title}>Назначенные курсы</h2>
//                 <div className={s.courses__wrapper}>
//                   {completedCourses.map((course, index) => (
//                     <Card
//                       key={index}
//                       img={course.lecture.image}
//                       alt="Картинка"
//                       title={course.lecture.title}
//                       chipText={course.lecture.tag || undefined}
//                       chip="green"
//                       descr={course.lecture.description}
//                       buttonType="round"
//                       buttonText=""
//                       id={course.lecture.id}
//                     />
//                   ))}
//                 </div>
//               </div>
//             )}
//         </div>
//       </Container>
//     </Layout>
//   );
// };
