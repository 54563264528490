// import s from "./CompetenceCard.module.css";
// import clsx from "clsx";
// import book from "../../assets/icons/book.svg";
// import ultralevel from "../../assets/icons/ultralevel.svg";
// import normallevel from "../../assets/icons/normallevel.svg";
// import lowlevel from "../../assets/icons/lowlevel.svg";
// import { useEffect, useState } from "react";
// import { Link } from "react-router-dom";

// export const CompetenceCard = ({ sphereId, sphereName }) => {
//   const [sphereResult, setSphereResult] = useState({});
//   const [date, setDate] = useState();

//   // ПОЛУЧАЕМ РЕЗУЛЬТАТ ОЦЕНОЧНЫХ СЕССИЙ ПО ДАННОЙ СФЕРЕ
//   useEffect(() => {
//     const getSphere = async () => {
//       try {
//         const response = await fetch(
//           `${process.env.REACT_APP_API_URL}/assessment-tests/sphere/${sphereId}`,
//           {
//             method: "GET",
//             headers: {
//               "Content-Type": "application/json",
//               Authorization: `Bearer ${localStorage.getItem("token")}`,
//             },
//           }
//         );
//         const data = await response.json();

//         // НАПИСАТЬ НОРМАЛЬНО ПО ЧЕЛОВЕЧЕСКИ И ОТФОРМАТИРОВАТЬ ВРЕМЯ В РУССКОМ ФОРМАТЕ
//         setDate(data[data.length - 1].updated_at?.split("T")[0]);
//         setSphereResult(data);
//       } catch (error) {
//         console.error(error);
//       }
//     };

//     getSphere();
//   }, [sphereId]);

//   console.log(sphereResult);
//   console.log(date);

//   return (
//     // ПОФИКСИТЬ НОРМАЛЬНО НАПИСАТЬ
//     <Link to={sphereResult.level ? `/sphere/${sphereId}` : ""}>
//       <div className={s.card}>
//         <div className={s.wrapper}>
//           <img className={s.sphere__icon} src={book} alt="book" />
//           <p className={s.sphere__name}>{sphereName}</p>
//           <p className={s.sphere__date}>{date}</p>
//         </div>

//         <div className={s.wrapper}>
//           <div className={s.level__rang}>
//             {!sphereResult.level ? (
//               <p className={clsx(s.level__text, s.level__text_disabled)}>
//                 Не установлено
//               </p>
//             ) : (
//               <>
//                 <img className={s.level__icon} src={normallevel} alt="level" />
//                 <p className={s.level__text}>Требуемый уровень</p>
//               </>
//             )}
//           </div>
//           <div className={s.level__wrapper}>
//             {sphereResult.level && (
//               <>
//                 <div className={s.level__rating}></div>
//                 <div className={s.level__rating}></div>
//                 <div
//                   className={clsx(s.level__rating, s.level__rating_normal)}
//                 ></div>
//               </>
//             )}
//           </div>
//         </div>
//       </div>
//     </Link>
//   );
// };

// import s from "./CompetenceCard.module.css";
// import clsx from "clsx";
// import book from "../../assets/icons/book.svg";
// import highlevel from "../../assets/icons/highlevel.svg";
// import normallevel from "../../assets/icons/normallevel.svg";
// import lowlevel from "../../assets/icons/lowlevel.svg";
// import { useEffect, useState } from "react";
// import { Link } from "react-router-dom";

// // ФУНКЦИЯ ДЛЯ ОПРЕДЕЛЕНИЯ УРОВНЯ РЕЗУЛЬТАТА
// const getLevelCategory = (level) => {
//   if (level < 69) {
//     return {
//       icon: lowlevel,
//       text: "Дефицитарный уровень",
//       className: s.level__text_low,
//     };
//   } else if (level <= 90) {
//     return {
//       icon: normallevel,
//       text: "Требуемый уровень",
//       className: s.level__text_normal,
//     };
//   } else {
//     return {
//       icon: highlevel,
//       text: "Уровень превосходства",
//       className: s.level__text_high,
//     };
//   }
// };

// // ФУНКЦИЯ ФОРМАТИРОВАНИЯ ДАТЫ
// function formatToRussianDate(isoDateString) {
//   const date = new Date(isoDateString);
//   const options = {
//     day: "numeric",
//     month: "long",
//     year: "numeric",
//   };
//   return date.toLocaleDateString("ru-RU", options).replace(" г.", "");
// }

// // ФУНКЦИЯ ОПРЕДЕЛЕНИЯ ЦВЕТА СТОЛБИКА С РЕЙТИНГОМ
// const getBarStyle = (level, isLast) => {
//   let height, backgroundColor;
//   switch (level) {
//     case "Дефицитарный уровень":
//       height = "27px";
//       backgroundColor = isLast ? "#FF7D1E" : "rgba(9, 48, 106, 0.4)";
//       break;
//     case "Требуемый уровень":
//       height = "54px";
//       backgroundColor = isLast ? "#8ac540" : "rgba(9, 48, 106, 0.4)";
//       break;
//     case "Уровень превосходства":
//       height = "81px";
//       backgroundColor = isLast ? "#00a442" : "rgba(9, 48, 106, 0.4)";
//       break;
//     default:
//       height = "27px";
//       backgroundColor = "rgba(9, 48, 106, 0.4)";
//   }
//   return { height, backgroundColor };
// };

// export const CompetenceCard = ({ sphereId, sphereName }) => {
//   // РЕЗУЛЬТАТЫ ВСЕХ ОЦЕНОЧНЫХ СЕССИЙ ПО ДАННОЙ СФЕРЕ КОМПЕТЕНЦИЙ
//   const [sphereAllResults, setSphereAllResults] = useState(null);

//   // РЕЗУЛЬТАТЫ ПОСЛЕДНЕЙ ОЦЕНОЧНОЙ СЕССИИ ПО ДАННОЙ СФЕРЕ КОМПЕНЦИЙ
//   const [sphereLastResult, setSphereLastResult] = useState(null);

//   // ДАТА ПОСЛЕДНЕЙ ОЦЕНОЧНОЙ СЕССИИ
//   const [date, setDate] = useState("");

//   // ПОЛУЧАЕМ С СЕРВЕРА РЕЗУЛЬТАТЫ ЗАВЕРШЕННЫХ СЕССИЙ ПО ДАННОЙ СФЕРЕ КОМПЕТЕНЦИЙ
//   useEffect(() => {
//     const fetchSphereData = async () => {
//       try {
//         const response = await fetch(
//           `${process.env.REACT_APP_API_URL}/assessment-tests/sphere/${sphereId}`,
//           {
//             headers: {
//               Authorization: `Bearer ${localStorage.getItem("token")}`,
//             },
//           }
//         );

//         const data = await response.json();

//         // console.log("ДАННЫЕ ПО СФЕРЕ ПЕРВОНАЧАЛЬНЫЕ", data);

//         if (data && data.length > 0) {
//           const latestData = data[data.length - 1];
//           console.log(
//             "ДАННЫЕ ПО СФЕРЕ ОТФОРМАТИРОВАННЫЕ",
//             `СФЕРА: ${sphereId} ${sphereName}`,
//             latestData
//           );
//           setDate(formatToRussianDate(latestData.updated_at));
//           setSphereAllResults(data);
//           setSphereLastResult(latestData);
//         }
//       } catch (error) {
//         console.error("Ошибка при получении данных сферы:", error);
//       }
//     };

//     fetchSphereData();
//   }, [sphereId]);

//   console.log(
//     `Карточка сферы ${sphereId}, сфера ${sphereName}`,
//     sphereAllResults,
//     `LAST RESULT::`,
//     sphereLastResult
//   );

//   const isLevelDefined =
//     sphereLastResult?.level !== null && sphereLastResult?.level !== undefined;

//   return (
//     // !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!! ЗАДАНИЕ, СДЕЛАТЬ LINK НЕАКТИВНЫМ !!!!!!!!!!!!!!!!!!!!!!!!!!!!
//     <Link to={isLevelDefined ? `/result/${sphereId}` : "#"}>
//       <div className={s.card}>
//         <div className={s.wrapper}>
//           <img className={s.sphere__icon} src={book} alt="book" />
//           <p className={s.sphere__name}>{sphereName}</p>
//           <p className={s.sphere__date}>{isLevelDefined && date}</p>
//         </div>

//         <div className={s.wrapper}>
//           <div className={s.level__rang}>
//             {isLevelDefined ? (
//               <div className={s.level__wrapper}>
//                 <img
//                   className={s.level__icon}
//                   src={getLevelCategory(sphereLastResult.level).icon}
//                   alt="level"
//                 />
//                 <p
//                   className={clsx(
//                     s.level__text,
//                     getLevelCategory(sphereLastResult.level).className
//                   )}
//                 >
//                   {getLevelCategory(sphereLastResult.level).text}
//                 </p>
//               </div>
//             ) : (
//               <p className={clsx(s.level__text, s.level__text_disabled)}>
//                 Не установлено
//               </p>
//             )}
//           </div>
//           <div className={s.level__wrapper}>
//             {isLevelDefined && (
//               <>
//                 {sphereAllResults?.map((el, i) => {
//                   return (
//                     <div
//                       className={s.level__rating}
//                       key={i}
//                       style={getBarStyle(
//                         getLevelCategory(el.level).text,
//                         i === sphereAllResults.length - 1
//                           ? true
//                           : sphereAllResults.length === 0
//                           ? true
//                           : false
//                       )}
//                     ></div>
//                   );
//                 })}
//               </>
//             )}
//           </div>
//         </div>
//       </div>
//     </Link>
//   );
// };

import s from "./CompetenceCard.module.css";
import clsx from "clsx";
import book from "../../assets/icons/book.svg";
import highlevel from "../../assets/icons/highlevel.svg";
import normallevel from "../../assets/icons/normallevel.svg";
import lowlevel from "../../assets/icons/lowlevel.svg";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Spinner } from "../../ui/Spinner/Spinner";

// ФУНКЦИЯ ДЛЯ ОПРЕДЕЛЕНИЯ УРОВНЯ РЕЗУЛЬТАТА
const getLevelCategory = (level) => {
  if (level < 69) {
    return {
      icon: lowlevel,
      text: "Дефицитарный уровень",
      className: s.level__text_low,
    };
  } else if (level <= 90) {
    return {
      icon: normallevel,
      text: "Требуемый уровень",
      className: s.level__text_normal,
    };
  } else {
    return {
      icon: highlevel,
      text: "Уровень превосходства",
      className: s.level__text_high,
    };
  }
};

// ФУНКЦИЯ ФОРМАТИРОВАНИЯ ДАТЫ
function formatToRussianDate(isoDateString) {
  const date = new Date(isoDateString);
  const options = {
    day: "numeric",
    month: "long",
    year: "numeric",
  };
  return date.toLocaleDateString("ru-RU", options).replace(" г.", "");
}

// ФУНКЦИЯ ОПРЕДЕЛЕНИЯ ЦВЕТА СТОЛБИКА С РЕЙТИНГОМ
const getBarStyle = (level, isLast) => {
  let height, backgroundColor;
  switch (level) {
    case "Дефицитарный уровень":
      height = "27px";
      backgroundColor = isLast ? "#FF7D1E" : "rgba(9, 48, 106, 0.4)";
      break;
    case "Требуемый уровень":
      height = "54px";
      backgroundColor = isLast ? "#8ac540" : "rgba(9, 48, 106, 0.4)";
      break;
    case "Уровень превосходства":
      height = "81px";
      backgroundColor = isLast ? "#00a442" : "rgba(9, 48, 106, 0.4)";
      break;
    default:
      height = "27px";
      backgroundColor = "rgba(9, 48, 106, 0.4)";
  }
  return { height, backgroundColor };
};

export const CompetenceCard = ({ sphereId, sphereName }) => {
  // РЕЗУЛЬТАТЫ ВСЕХ ОЦЕНОЧНЫХ СЕССИЙ ПО ДАННОЙ СФЕРЕ КОМПЕТЕНЦИЙ
  const [sphereAllResults, setSphereAllResults] = useState(null);

  // РЕЗУЛЬТАТЫ ПОСЛЕДНЕЙ ОЦЕНОЧНОЙ СЕССИИ ПО ДАННОЙ СФЕРЕ КОМПЕНЦИЙ
  const [sphereLastResult, setSphereLastResult] = useState(null);

  // ДАТА ПОСЛЕДНЕЙ ОЦЕНОЧНОЙ СЕССИИ
  const [date, setDate] = useState("");

  // Состояние загрузки
  const [loading, setLoading] = useState(true);

  // ПОЛУЧАЕМ С СЕРВЕРА РЕЗУЛЬТАТЫ ЗАВЕРШЕННЫХ СЕССИЙ ПО ДАННОЙ СФЕРЕ КОМПЕТЕНЦИЙ
  useEffect(() => {
    const fetchSphereData = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/assessment-tests/sphere/${sphereId}`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );

        const data = await response.json();

        // console.log("ДАННЫЕ ПО СФЕРЕ ПЕРВОНАЧАЛЬНЫЕ", data);

        if (data && data.length > 0) {
          const latestData = data[data.length - 1];
          // console.log(
          //   "ДАННЫЕ ПО СФЕРЕ ОТФОРМАТИРОВАННЫЕ",
          //   `СФЕРА: ${sphereId} ${sphereName}`,
          //   latestData
          // );
          setDate(formatToRussianDate(latestData.updated_at));
          setSphereAllResults(data);
          setSphereLastResult(latestData);
        }
      } catch (error) {
        console.error("Ошибка при получении данных сферы:", error);
      } finally {
        setLoading(false); // Устанавливаем загрузку в false после завершения запроса
      }
    };

    fetchSphereData();
  }, [sphereId]);

  // console.log(
  //   `Карточка сферы ${sphereId}, сфера ${sphereName}`,
  //   sphereAllResults,
  //   `LAST RESULT::`,
  //   sphereLastResult
  // );

  // const isLevelDefined =
  //   sphereLastResult?.level !== null && sphereLastResult?.level !== undefined;

  const isLevelDefined = sphereAllResults?.find((result) => {
    return result.level !== null && result.level !== undefined;
  });

  return (
    <Link to={isLevelDefined ? `/result/${sphereId}` : "#"}>
      <div className={s.card}>
        {loading ? (
          <Spinner className={s.spinner} />
        ) : (
          <>
            <div className={s.wrapper}>
              <img className={s.sphere__icon} src={book} alt="book" />
              <p className={s.sphere__name}>{sphereName}</p>
              <p className={s.sphere__date}>{isLevelDefined && date}</p>
            </div>

            <div className={s.wrapper}>
              <div className={s.level__rang}>
                {isLevelDefined ? (
                  <div className={s.level__wrapper}>
                    <img
                      className={s.level__icon}
                      src={getLevelCategory(sphereLastResult.level).icon}
                      alt="level"
                    />
                    <p
                      className={clsx(
                        s.level__text,
                        getLevelCategory(sphereLastResult.level).className
                      )}
                    >
                      {getLevelCategory(sphereLastResult.level).text}
                    </p>
                  </div>
                ) : (
                  <p className={clsx(s.level__text, s.level__text_disabled)}>
                    Не установлено
                  </p>
                )}
              </div>
              {/* <div className={s.level__wrapper}>
                {isLevelDefined &&
                  sphereAllResults?.map((el, i, arr) => {
                    if (el.level !== null && el.level !== undefined) {
                      return (
                        // <div
                        //   className={s.level__rating}
                        //   key={i}
                        //   style={getBarStyle(
                        //     getLevelCategory(el.level).text,
                        //     i === sphereAllResults.length - 1
                        //       ? true
                        //       : sphereAllResults.length === 0
                        //       ? true
                        //       : false
                        //   )}
                        // ></div>
                        <>
                          <div
                            className={s.level__rating}
                            style={getBarStyle(
                              getLevelCategory(el.level).text,
                              i === arr.length - 1
                            )}
                          />
                        </>
                      );
                    }
                  })}
              </div> */}

              <div className={s.level__wrapper}>
                {isLevelDefined &&
                  (() => {
                    const lastValidIndex = sphereAllResults.reduce(
                      (lastIndex, el, i) =>
                        el.level !== null && el.level !== undefined
                          ? i
                          : lastIndex,
                      -1
                    );

                    return sphereAllResults.map((el, i) => {
                      if (el.level !== null && el.level !== undefined) {
                        return (
                          <div
                            className={s.level__rating}
                            key={i}
                            style={getBarStyle(
                              getLevelCategory(el.level).text,
                              i === lastValidIndex
                            )}
                          />
                        );
                      }
                      return null;
                    });
                  })()}
              </div>
            </div>
          </>
        )}
      </div>
    </Link>
  );
};
