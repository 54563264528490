import React, { useState } from "react";
import { useForm } from "react-hook-form";
import s from "./ActivateForm.module.css";
import { Button } from "../../ui/Button/Button";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import Input from "../../ui/Input/Input";

// Проверки на количество символов в инпуте с паролем и совпадение паролей
/* const schema = Yup.object().shape({
  password: Yup.string().min(
    8,
    "Ненадежный пароль.\nПароль должен быть не менее 8 символов, включать буквы в верхнем и нижнем регистре, содержать цифры и другие знаки"
  ),
  confirmPassword: Yup.string()
    .required("Подтверждение пароля обязательно")
    .oneOf([Yup.ref("password")], "Пароли не совпадают"),
}); */

const schema = Yup.object().shape({
  password: Yup.string()
    .min(
      8,
      "Ненадежный пароль. Пароль должен содержать не менее 8 символов. буквы в верхнем и нижнем регистре, цифры и другие знаки"
    )
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&#])/,
      "Пароль должен содержать буквы верхнего и нижнего регистра, цифры и символы: @$!%*?&#"
    ),
  confirmPassword: Yup.string()
    .required("Подтверждение пароля обязательно")
    .oneOf([Yup.ref("password")], "Пароли не совпадают"),
});
export const ActivateForm = ({ email, code }) => {
  // Ошибка активации
  const [activateError, setActivateError] = useState(null);

  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema), mode: "onSubmit" });

  // Отправка формы. ВОЗМОЖНО СТОИТ ПОДУМАТЬ ЧТО-ТО С ФЕТЧАМИ, КАК ТО ПОКРАСИВЕЙ.
  const onSubmit = async (data) => {
    // Отправляемые данные
    const payload = {
      code: code,
      email: email,
      password: data.password,
    };

    // console.log("Отправляемые данные:", payload);

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/activate`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(payload),
        }
      );

      const jsonData = await response.json();

      if (response.ok) {
        // console.log("Ответ сервера:", jsonData);

        // Логинизация при активации, потому что токен по умолчанию не приходит. ВОЗМОЖНО СТОИТ ПОПРОСИТЬ ПОЛУЧЕНИЕ ТОКЕНА ПРИ АКТИВАЦИИ НА СТОРОНЕ БЭКА
        const loginPayload = {
          email: email,
          password: data.password,
        };

        const loginResponse = await fetch(
          `${process.env.REACT_APP_API_URL}/login`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(loginPayload),
          }
        );

        const loginData = await loginResponse.json();

        if (loginResponse.ok) {
          localStorage.setItem("token", loginData.token);
          navigate("/profile", { replace: true });
        } else {
          setActivateError(loginData.error);
          console.error(
            "Ошибка входа:",
            loginData.error || `Status: ${loginResponse.status}`
          );
          throw new Error(
            loginData.error || `HTTP error! status: ${loginResponse.status}`
          );
        }
      } else {
        setActivateError(jsonData.error);
        console.error(
          "Ошибка активации:",
          jsonData.error || `Status: ${response.status}`
        );
        throw new Error(
          jsonData.error || `HTTP error! status: ${response.status}`
        );
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className={s.form} noValidate>
      <div className={s.wrapper}>
        <Input
          type="password"
          placeholder="Введите пароль"
          name="password"
          register={register}
          errors={errors}
          errorMessage={activateError}
          showToggle
          backgroundColor="white"
        />

        <Input
          type="password"
          placeholder="Повторите ваш пароль"
          name="confirmPassword"
          register={register}
          errors={errors}
          errorMessage={activateError}
          showToggle
          backgroundColor="white"
        />
      </div>

      <Button
        className={s.button}
        submit
        text="Сохранить и войти"
        type={"blue"}
      />

      {activateError && <p className={s.error}>{activateError}</p>}
    </form>
  );
};
